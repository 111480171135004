import { Injectable } from '@angular/core';
import { ElectronService } from '@app/electron/electron.service';
import { Call } from '@app/phone/models/call.model';
import { CompanionCall } from '@app/phone/models/companion.models';
import { PhoneService } from '@app/phone/services/phone.service';
import { SipjsService } from '@app/phone/services/sipjs.service';
import { AudioSettings } from '@app/preferences/models/settings.models';
import { SettingsService } from '@app/preferences/services/settings.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElectronChannel, HidEvent } from '@shared/types';
import { combineLatest, filter, Observable, of, startWith, switchMap } from 'rxjs';

/**
 * Monitors messages from Electron (aka "main") as events occur related to HID.
 * If an HID is available, the user should be able to control calls (answer, hang up, mute, unmute) using the HID.
 *
 * If no HID is connected, the operations will result in no-ops.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HidRendererService {
  constructor(
    private electronService: ElectronService,
    private sipJsService: SipjsService,
    private phoneService: PhoneService,
    private settingsService: SettingsService
  ) {
    // Observe the status of the current visible call and send the status to the HID device
    this.setUpCallStatusMonitoring();
    this.setUpElectronEventHandlers();
  }

  public init() {}
  public refreshData(): Observable<void> {
    return of(void 0);
  }

  private setUpCallStatusMonitoring() {
    this.phoneService.currentVisibleCall$
      .pipe(
        untilDestroyed(this),
        filter((call): call is Call => call !== undefined && !(call instanceof CompanionCall)),
        switchMap((call) => this.phoneService.observeStatusChanges(call.id))
      )
      .subscribe((status) => {
        this.electronService.send(ElectronChannel.CurrentCallSessionStatus, { status });
      });

    // Observe the current visible call as well as the muted states. Emit events to the HID
    // whenver the current call's muted state changes
    combineLatest([
      this.phoneService.currentVisibleCall$,
      this.phoneService.callMuted$.pipe(
        startWith({
          call: this.phoneService.currentVisibleCall,
          isMuted: this.phoneService.currentVisibleCall?.muted ?? false,
        })
      ),
    ])
      .pipe(
        untilDestroyed(this),
        filter(
          ([currentCall, muteEvent]) =>
            !(currentCall instanceof CompanionCall) && currentCall?.id === muteEvent.call?.id
        )
      )
      .subscribe(([_, muteEvent]) => {
        this.electronService.send(ElectronChannel.CallMute, { muted: muteEvent.isMuted ?? false });
      });

    // Observe calls and send the status to the HID device
    combineLatest([this.phoneService.visibleCalls$, this.phoneService.incomingCalls$, this.sipJsService.calls$])
      .pipe(untilDestroyed(this))
      .subscribe(([visibleCalls, incomingCalls, calls]) => {
        this.electronService.send(ElectronChannel.CallState, {
          hasIncoming: incomingCalls.length > 0,
          hasVisible: visibleCalls.length > 0,
          hasActive: calls.length > 0,
        });
      });

    this.settingsService.audioSettings$.pipe(untilDestroyed(this)).subscribe((settings) => {
      this.sendAudioSettings(settings);
    });
  }

  /**
   * Set up handlers for HID events from the Electron main process. For these actions, we infer the call
   * to be acted upon based on current app state since the HID device does not have a direct reference to the call.
   *
   * Typically we'll be acting upon the current visible call, but in some cases we may need to act upon an incoming call.
   */
  private setUpElectronEventHandlers() {
    this.electronService.on(ElectronChannel.HID, (data) => {
      const currentCall = this.phoneService.currentVisibleCall;
      if (currentCall instanceof CompanionCall) {
        return;
      }

      switch (data.event) {
        case HidEvent.Mute: {
          if (currentCall) {
            this.phoneService.toggleCallMuted(currentCall.id);
          }

          break;
        }
        case HidEvent.RingingRequest: {
          // If there are incoming calls, answer the most recent one.
          const incomingCalls = this.phoneService.incomingCalls;

          if (incomingCalls.length > 0) {
            const mostRecentIncomingCall = incomingCalls.at(-1)!;
            if (mostRecentIncomingCall instanceof Call) {
              this.phoneService.answerIncomingCall(mostRecentIncomingCall.id);
            }
          } else if (currentCall) {
            this.phoneService.endCallById(currentCall.id);
          }
        }
      }
    });

    this.electronService.on(ElectronChannel.RequestEmitAudioSettings, () => {
      this.sendAudioSettings(this.settingsService.audioSettings);
    });
  }

  private sendAudioSettings(settings: AudioSettings) {
    this.electronService.send(ElectronChannel.AudioSettingsChange, {
      speakerDevice: settings.speakerDevice,
      speakerLabel: settings.speakerLabel,
      microphoneDevice: settings.microphoneDevice,
      microphoneLabel: settings.microphoneLabel,
    });
  }
}
